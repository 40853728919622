<template>
  <main>
    <div
      class="container"
      v-if="connectors"
    >
      <div class="filter">
        <filter-list
          :json="area"
          :title="'Area'"
          @model="onModelChangeArea"
        />
        <filter-list
          ref="country-ref"
          :json="country"
          :title="'Country'"
          @model="onModelChangeCountry"
        />
        <filter-list
          ref="family-ref"
          :json="family"
          :title="'Category'"
          @model="onModelChangeFamily"
        />
      </div>
      <div class="row">
        <div class="infoBox">
          <span class="big">{{ connectorsRange }}</span> &nbsp; <span>connectors</span>
        </div>
        <div class="row">
          <p class="title_infos">
            Here are all the connections we have with utility suppliers and data providers around the world. If the data provider you are looking for is not in this list you can get in touch with our Sales or Client team : we develop new connectors every week !
          </p>
        </div>
      </div>
      <connectors-list
        :raw-connectors="connectors"
        :family="selectedFamily"
        :area="selectedArea"
        :country="selectedCountry"
        @connectors-range="connectorsRange=$event"
      />
    </div>
    <div v-else>
      No Data
    </div>
  </main>
</template>

<script>
import connectors_from_endpoint from '@/connectors.json'
import list_families from '@/families.json'
import list_families_by_area from '@/families_by_area.json'
import {
  isCountryInArea,
  isFamilyInArea,
  isFamilyInCountry,
  isValueSelected,
  getAreaFromFamilyByArea,
  getCountryFromFamilyByArea,
  getCountryFromArea,
  getFamilyFromCountry,
} from "@/utils";
import ConnectorsList from '@/components/ConnectorsList'
import FilterList from '@/components/FilterList'

export default {
  name: 'App',
  components: {
    ConnectorsList, FilterList
  },
  data() {
    return {
      connectors: connectors_from_endpoint.connectors,
      selectedFamily : [],
      selectedCountry : [],
      selectedArea: [],
      connectorsRange: 0,
      listFamily: list_families.families,
      listCountry: getCountryFromFamilyByArea(list_families_by_area.connectors),
      listArea: getAreaFromFamilyByArea(list_families_by_area.connectors),
      listFamilyByArea: list_families_by_area.connectors,
      availableFamily: list_families.families,
      availableCountry: getCountryFromFamilyByArea(list_families_by_area.connectors)
    }
  },
  mounted() {
    this.selectedFamily = []
    this.selectedArea =[]
    this.selectedCountry = []
    this.connectorsRange = this.connectors.length
  },
  methods: {
    resetSelectionField(selection, ref) {
      selection = []
      Object.assign(this.$refs[ref].$data, {'selected': []})
    },
    onModelChangeFamily (value) {
      this.selectedFamily = value
    },
    onModelChangeArea (value) {
      if (isValueSelected(value)) {
        const area = value[0]
        const selectedCountry = this.selectedCountry && this.selectedCountry.length ? this.selectedCountry[0] : null
        const selectedFamily = this.selectedFamily && this.selectedFamily.length ? this.selectedFamily[0] : null

        this.availableCountry = getCountryFromArea(this.listFamilyByArea, area)
        if (selectedCountry && !isCountryInArea(this.listFamilyByArea, area, selectedCountry)) {
          this.resetSelectionField(this.selectedCountry, "country-ref")
          this.availableFamily = this.listFamilyByArea[area]["all"]
        }
        if (selectedFamily && !isFamilyInArea(this.listFamilyByArea, area, selectedFamily)) {
          this.resetSelectionField(this.selectedFamily, "family-ref")
          this.availableFamily = this.listFamilyByArea[area]["all"]
        }
        if (!selectedCountry || !selectedFamily) {
          this.availableFamily = this.listFamilyByArea[area]["all"]
        }
      } else if (!isValueSelected(this.selectedCountry) || !isValueSelected(value)) {
        this.availableCountry = this.listCountry
        this.availableFamily = this.listFamily
      }
      this.selectedArea = value
    },
    onModelChangeCountry (value) {
      if (isValueSelected(value)) {
        const country = value[0]
        const selectedFamily = this.selectedFamily && this.selectedFamily.length ? this.selectedFamily[0] : null

        this.availableFamily = getFamilyFromCountry(this.listFamilyByArea, country)
        if (selectedFamily && !isFamilyInCountry(this.listFamilyByArea, country, selectedFamily)) {
          this.resetSelectionField(this.selectedFamily, "family-ref")
        }
      } else if (!isValueSelected(this.selectedArea)){
        this.availableFamily = this.listFamily
      } else if (isValueSelected(this.selectedArea) && !isValueSelected(value)) {
        this.availableFamily = this.listFamilyByArea[this.selectedArea[0]]["all"]
      }
      this.selectedCountry = value
    },
  },
  computed: {
    area() {
      const area = this.listArea
      return area.sort()
    },
    country() {
      const countries = this.availableCountry
      return countries.sort()
    },
    family() {
      const family = this.availableFamily
      return family.sort()
    },
  }
}
</script>

<style lang="scss">
body {
    margin: 0;
    background-color: #FAFAFA;
}

.container {
    width: 100%;
    max-width: 1185px;
    margin: auto;
    padding: 24px;

    box-sizing: border-box;

    @media (max-width: 425px) {
        padding: 12px;
    }
}

.filter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2%;
}

.row {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-variant-ligatures: normal;
    font-size: 1rem;
    letter-spacing: 1px;
}

.title_infos {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-variant-ligatures: normal;
    font-size: 1rem;
    letter-spacing: 1px;
    padding-left: 25px;
}


.infoBox {
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 64px;
    padding: 0 8px;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);

    &:hover {
        transform:scale(1.02)
    }

    span {
        font-size: 14px;
    }

    .big {
        font-size: 18px;
        line-height: 18px;
    }
}

.vs__dropdown-option {
  min-height: 28px;

  &--selected {
    background-color: #5897fb;
    color: white!important;
  }
}
.vs__actions {
  cursor: pointer;
}
</style>
