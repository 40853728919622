<template>
  <transition-group
    name="fade"
    tag="ul"
  >
    <connector
      v-for="(item, index) in connectors"
      :key="`${item.name}-${index}`"
      :element="item"
    />
  </transition-group>
</template>

<script>
import Connector from './Connector'
import _ from 'underscore'

export default {
    components: {
        Connector
    },
    props: {
        rawConnectors: { default: null, type: Array },
        family: { default: () => [], type: Array },
        area: { default: () => [], type: Array },
        country: { default: () => [], type: Array }
    },
    watch: {
      connectors() {
        this.$emit('connectors-range', this.connectors.length)
      }
    },
    computed: {
      connectors() {
        let connectorsToDisplay = _.each(this.rawConnectors, d => {
          d.name = d.name.charAt(0).toUpperCase() + d.name.substring(1)
        })
        connectorsToDisplay = this.filterConnectorsWithFamily(connectorsToDisplay)
        connectorsToDisplay = this.filterConnectorsWithArea(connectorsToDisplay)
        connectorsToDisplay = this.filterConnectorsWithCountry(connectorsToDisplay)
        return connectorsToDisplay
      },
    },
    methods: {
        filterConnectorsWithFamily(connectors) {
          if(!this.family || this.family.length === 0) {
                return _.sortBy(connectors, "name")
            }
            return _.sortBy(connectors.filter(connector => { return _.contains(connector.family, this.family[0]) }), "name")

        },
        filterConnectorsWithArea(connectors) {
          if(!this.area || this.area.length === 0) {
                return _.sortBy(connectors, "name")
            }
            return _.sortBy(connectors.filter(connector => { return connector.area === this.area[0] }), "name")

        },
        filterConnectorsWithCountry(connectors) {
          if(!this.country || this.country.length === 0) {
                return _.sortBy(connectors, "name")
            }
            return _.sortBy(connectors.filter(connector => { return connector.country === this.country[0] }), "name")

        }
    },
}
</script>

<style lang="scss" scoped>
ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin: 0;
    padding: 0;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-1000px);
}

</style>
