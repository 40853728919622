<template>
  <div class="row">
    <div class="input">
      <label class="label">
        {{ title }}
      </label>
      <v-select
        v-model="selected"
        :close-on-select="true"
        searchable
        :options="json"
      />
    </div>
  </div>
</template>

<script>
export default {
    props: {
        json: { default: null, type: Array },
        title: {default: 'Category', type: String},
    }, 
    data() {
        return {
            selected: []
        }
    },
    watch: {
        selected() {
            this.$emit('model', this.selected ? this.toArray : null)
        }
    },
    computed: {
        toArray() {
            if(Array.isArray(this.selected)) {
                return this.selected
            }
            else {
                return this.selected.split()
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.input {
    min-width: 300px;
}

.label {
    display: block;
    font-size: 14px;
    padding-bottom: 4px;
}

</style>
