<template>
  <li>
    <div>
      <flag
        :iso="element.flag"
        :squared="false"
        style="margin-left: auto;"
      />
    </div>
    <img
      loading="lazy"
      v-if="element.logo"
      :src="'/img/' + element.logo"
      alt="logo"
    >
    <span v-else>{{ element.name }}</span>
    <p v-if="element.logo">
      {{ element.name }}
    </p>
  </li>
</template>

<script>
export default {
    props: {
        element: { default: null, type: Object },
    },
}
</script>

<style lang="scss" scoped>
li {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 180px;
    max-width: 180px;
    height: 180px;
    margin: 0 12px 12px 0;
    padding: 8px;
    background-color: white;

    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    border-radius: 3px;
    list-style: none;

    // animation: fadein 2s;

    &:hover {
        transform:scale(1.01)
    }

    p {
        margin: auto 0 0 0;
        padding: 4px;
        text-align: center;
        color: rgb(255, 152, 0);
        font-family: "Lato", sans-serif;
        font-weight: 100;
        font-variant-ligatures: normal;
        font-size: 1rem;
        letter-spacing: 1px;
    }

    img {
        width: 120px;    
        max-height: 100px;
        object-fit: contain;
        margin: auto;
    }

    i {
        width: 12px;
        position: absolute;
        font-family: 'Font Awesome 5 Pro';
        color: rgb(255, 152, 0);
        display:inline-block;
        margin-right:10px;
    }

    flag {
        display:inline-block;
    }

    @media (max-width: 425px) {
        padding: 12px;
        width: calc(50% - 12px)
    }

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100%;
        font-size: 20px;
        font-weight: 60;
        font-family: sans-serif;
    }
}


</style>
