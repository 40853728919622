export function rmDuplicate(arr) {
    return [...new Set(arr)];
}

export function isCountryInArea(familyByArea, area, country) {
    return getCountryFromArea(familyByArea, area).includes(country)
}

export function isFamilyInArea(familyByArea, area, family) {
    return familyByArea[area]["all"].includes(family)
}

export function isFamilyInCountry(familyByArea, country, family) {
    return familyByArea[getAreaFromCountry(familyByArea, country)][country].includes(family)
}

export function isValueSelected(selectedValue) {
    return selectedValue && selectedValue.length
}

export function getCountryFromFamilyByArea(familyByArea) {
    let countries = []
    for (const area of Object.keys(familyByArea)) {
        for (const country of Object.keys(familyByArea[area])) {
            countries = countries.concat(country)
        }
    }
    return rmDuplicate(countries.filter(country => country !== "all"))
}

export function getAreaFromFamilyByArea(familyByArea) {
    return Object.keys(familyByArea)
}
function getAreaFromCountry(familyByArea, country) {
    for (const area of Object.keys(familyByArea)) {
        if (getCountryFromArea(familyByArea, area).includes(country)) {
            return area
        }
    }
}

export function getCountryFromArea(familyByArea, area) {
    return rmDuplicate(Object.keys(familyByArea[area]).filter(country => country !== "all"))
}

export function getFamilyFromCountry(familyByArea, country) {
    return familyByArea[getAreaFromCountry(familyByArea, country)][country]
}