import Vue from 'vue'
import Router from 'vue-router'
import ConnectorPage from './views/ConnectorPage.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'connector-page',
      component: ConnectorPage
    },
  ]
})
