import Vue from 'vue'
import App from './App.vue'
import underscore from 'underscore';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import FlagIcon from 'vue-flag-icon'
import router from './router'


require("@fortawesome/fontawesome-pro/css/all.css")

Vue.component('v-select', vSelect)

Vue.use(underscore);
Vue.use(FlagIcon);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
