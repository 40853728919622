<template>
  <main>
    <navbar />
    <router-view />
  </main>
</template>

<script>

import navbar from '@/components/Navbar'
export default {
  components: {
    navbar
  }
}
</script>
