<template>
  <nav>
    <img
      src="/img/deepki_logo_white_big.png"
      alt="Deepki"
    >
  </nav>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
nav {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding: 4px 24px;
    background-color: rgba(0, 150, 136, 1);
}

img {
    height: 100%;
}

</style>
